import { capitalize } from "lodash";

export const MAX_UINT256 = BigInt(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
);

/// TODO: GET initial share price from contract

export const INITIAL_SHARE_PRICE = 1;

/// TODO: Get theses values in another way
export const DECIMALS = {
  SHARES: 18,
  USDC: 6,
  NAV: 8,
  REDEEM_FEE: 18,
};

/// TODO: Get theses values with contract interactions
export const SYMBOLS = {
  USDC: "USDC",
  USD: "USD",
  SHARES: "kpkUSDafv0",
};

export const DEBANK_CHAINS: Record<string, string> = {
  xdai: "Gnosis",
  eth: "Ethereum",
  arb: "Arbitrum",
  op: "Optimism",
  base: "Base",
};

export const DEBANK_PROTOCOLS: Record<string, string> = {
  Gnosis: "Savings xDAI",
};

export const DEBANK_PROTOCOL_IDS: Record<string, string> = {
  xdai_gnosis: "Savings xDAI",
  aave3: "Aave V3",
  base_aave3: "Aave V3",
  arb_aave3: "Aave V3",
  op_aave3: "Aave V3",
  eth_aave3: "Aave V3",
  xdai_aave3: "Aave V3",
  makerdao: "Maker",
  compound3: "Compound V3",
};

export function getDebankChainNameById(chainId: string) {
  return DEBANK_CHAINS[chainId] ?? capitalize(chainId);
}

export function getDebankProtocolName(protocolName: string) {
  return DEBANK_PROTOCOLS[protocolName] ?? capitalize(protocolName);
}

export function getDebankProtocolNameById(protocolId: string) {
  return DEBANK_PROTOCOL_IDS[protocolId] ?? capitalize(protocolId);
}
