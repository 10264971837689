export const URL_PATHS = {
  OPERATOR: "/operator",
  REPORT: "/dashboard/report",
  TRANSACTIONS: "/dashboard/transactions",
  SUBSCRIBE: "/subscribe",
  REDEEM: "/redeem",
  NOT_FOUND: "/404",
};

export const PROTECTED_PATHS = [
  URL_PATHS.TRANSACTIONS,
  URL_PATHS.SUBSCRIBE,
  URL_PATHS.REDEEM,
];

export const SECTION_IDS = {
  FUND_PROPERTIES: "fund-properties",
  FUND_PERFORMANCE: "fund-performance",
  PORTFOLIO: "portfolio",
  USER_POSITION: "user-position",
};

export const BASE_SECTIONS = [
  SECTION_IDS.FUND_PROPERTIES,
  SECTION_IDS.FUND_PERFORMANCE,
  SECTION_IDS.PORTFOLIO,
];

export const reportSections = (isLoggedIn: boolean) => {
  const sections = [...BASE_SECTIONS];
  if (isLoggedIn) {
    sections.push(SECTION_IDS.USER_POSITION);
  }
  return sections;
};
