import {
  ArcElement,
  Chart,
  type ChartData,
  type ChartOptions,
} from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useMemo } from "react";
import { formatToPercentage, formatToUSD } from "@/utils/formatters";

Chart.register(ArcElement, ChartDataLabels);
interface ChartDataItem {
  label: string;
  value: number;
}

const CHART_COLORS = [
  "#2F2F2F",
  "#535353",
  "#808080",
  "#B6B6B6",
  "#C8C8C8",
  "#DDDDDD",
];

export function DoughnutChart({ data }: { data: ChartDataItem[] }) {
  const totalSum = useMemo(() => {
    return data.reduce((acc, item) => acc + item.value, 0);
  }, [data]);

  const options = useMemo<ChartOptions<"doughnut">>(
    () => ({
      cutout: "52%",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onClick: () => {},
          font: {
            weight: "normal",
            size: 18,
            family: "var(--font-ibm-sans)",
          },
          position: "bottom",
          labels: {
            boxWidth: 37,
            boxHeight: 25,
            padding: 20,
            filter: (legendItem: any) => {
              const value = data[legendItem.index].value;
              const portion = value / totalSum;
              return portion > 0.01;
            },
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              if (!data) return;
              const value = tooltipItem.parsed;
              return formatToUSD(value);
            },
          },
        },
        datalabels: {
          formatter: (value: number) => {
            const portion = value / totalSum;
            return formatToPercentage(portion);
          },
          color: "white",
          font: {
            weight: "normal",
            size: 14,
            family: "var(--font-ibm-mono)",
          },
        },
      },
    }),
    [totalSum, data],
  );

  const finalData = useMemo<ChartData<"doughnut">>(
    () => ({
      labels: data.map((item) => item.label),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: CHART_COLORS,
          borderWidth: 1,
        },
      ],
    }),
    [data],
  );

  return <Doughnut data={finalData} options={options} />;
}
