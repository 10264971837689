import React, { useState, useMemo } from "react";
import { useHistoricalSharePrice } from "@/hooks/nav/useHistoricalSharePrice";
import BaseVariationChart from "./BaseVariationChart";
import { DateRangeType } from "@/constants/date";
import { getDateRangeForTab } from "@/utils/date_time";

function SharePriceVariationChart() {
  const [currentTab, setCurrentTab] = useState<DateRangeType>(
    DateRangeType.All,
  );
  const [customDateRange, setCustomDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>();

  const dateRange = useMemo(() => {
    return customDateRange ?? getDateRangeForTab(currentTab);
  }, [currentTab, customDateRange]);

  const { data, isError } = useHistoricalSharePrice(dateRange);

  return (
    <BaseVariationChart
      title="Share price"
      data={data}
      isError={isError}
      isLoading={data === undefined}
      label="Share Price"
      currentTab={currentTab}
      customDateRange={customDateRange}
      onTabChange={setCurrentTab}
      onCustomDateChange={setCustomDateRange}
    />
  );
}

export default SharePriceVariationChart;
