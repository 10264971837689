import { INITIAL_SHARE_PRICE } from "@/constants/wallet";
import LabelValue from "../LabelValue";
import { useFundStateContext } from "@/context/fundStateContext";
import {
  calculateAnnualizedReturn,
  calculateTotalReturnPercentage,
} from "../../_helpers/fund_calculator";
import { useFundPerformance } from "@/hooks/nav/useFundPerformance";
import { formatToPercentage } from "@/utils/formatters";

export default function FundPerformanceData() {
  const { data: fundState, isFetchedAfterMount: isFundStateFetchedAfterMount } =
    useFundStateContext();

  const {
    data: fundPerformance,
    isFetchedAfterMount: isFundPerformanceFetchedAfterMount,
  } = useFundPerformance();

  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(150px,auto))] gap-x-20 gap-y-10 flex-wrap">
      <LabelValue
        label="Total return"
        isFetched={isFundStateFetchedAfterMount}
        value={
          fundState !== undefined
            ? calculateTotalReturnPercentage(
                fundState.scaledSharePrice,
                INITIAL_SHARE_PRICE,
              )
            : undefined
        }
      />

      <LabelValue
        label="Annualised return"
        isFetched={isFundStateFetchedAfterMount}
        value={
          fundState?.startTimestamp
            ? calculateAnnualizedReturn(
                fundState.scaledSharePrice,
                INITIAL_SHARE_PRICE,
                fundState.startTimestamp,
              )
            : undefined
        }
      />
      <LabelValue
        isFetched={isFundPerformanceFetchedAfterMount}
        label="Alpha"
        value={
          fundPerformance?.alpha
            ? formatToPercentage(fundPerformance.alpha)
            : undefined
        }
      />
      <LabelValue
        isFetched={isFundPerformanceFetchedAfterMount}
        label="Beta"
        value={fundPerformance?.beta ? fundPerformance.beta.toFixed(2) : "-"}
      />
      <LabelValue label="Sharpe ratio" value="-" />
    </div>
  );
}
