import {
  useState,
  forwardRef,
  useCallback,
  useRef,
  useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";
import CalendarIcon from "../Icons/Calendar";
import { formatShortDate, formatToISODate } from "@/utils/date_time";
import "react-datepicker/dist/react-datepicker.css";
import "@/styles/date-picker.css";

interface DateRangePickerProps {
  onDateChange: (dates: { startDate: Date; endDate: Date } | undefined) => void;
  minDate?: Date;
  maxDate?: Date;
}

export interface DateRangePickerRef {
  reset: () => void;
}

export type DateRange = {
  startDate: Date | null;
  endDate: Date | null;
};

const DateRangePicker = forwardRef<DateRangePickerRef, DateRangePickerProps>(
  ({ onDateChange, minDate, maxDate }, ref) => {
    const [dateRange, setDateRange] = useState<DateRange>({
      startDate: null,
      endDate: null,
    });

    const previousValidRange = useRef<DateRange>({
      startDate: null,
      endDate: null,
    });

    useImperativeHandle(ref, () => ({
      reset: () => {
        setDateRange({ startDate: null, endDate: null });
        previousValidRange.current = { startDate: null, endDate: null };
      },
    }));

    const handleChange = useCallback(
      (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        const newRange = { startDate: start, endDate: end };

        setDateRange(newRange);

        if (start && end) {
          previousValidRange.current = newRange;
          onDateChange({
            startDate: start,
            endDate: end,
          });
        } else if (!start && !end) {
          previousValidRange.current = newRange;
          onDateChange(undefined);
        }
      },
      [onDateChange],
    );

    const handleCalendarClose = useCallback(() => {
      if (dateRange.startDate && !dateRange.endDate) {
        setDateRange(previousValidRange.current);
      }
    }, [dateRange]);

    return (
      <div>
        <DatePicker
          selectsRange
          onChange={handleChange}
          onCalendarClose={handleCalendarClose}
          startDate={dateRange.startDate ?? undefined}
          endDate={dateRange.endDate ?? undefined}
          minDate={minDate}
          maxDate={maxDate}
          isClearable
          customInput={<DatePickerInput dateRange={dateRange} />}
        />
      </div>
    );
  },
);
DateRangePicker.displayName = "DateRangePicker";

const DatePickerInput = forwardRef<
  React.ElementRef<"div">,
  { dateRange: DateRange } & React.ComponentPropsWithRef<"div">
>(({ dateRange, onClick, className, ...props }, ref) => (
  <div
    ref={ref}
    onClick={onClick}
    className={`flex items-center h-9 border-gray-900 rounded-md border bg-gray-200 cursor-pointer ${className || ""}`}
    {...props}
  >
    <div className="px-4 bg-gray-100 rounded-l-md h-full flex items-center">
      <CalendarIcon />
    </div>
    <div
      className="px-2 w-52 text-sm
     h-full flex items-center"
    >
      {dateRange && (dateRange.startDate || dateRange.endDate) ? (
        <div className="text-gray-900 font-sans font-semibold text-[15px] flex items-center">
          {`${formatShortDate(dateRange.startDate)}  -  ${formatShortDate(dateRange.endDate)}`}
        </div>
      ) : (
        <div className="text-gray-800 font-sans">Choose period</div>
      )}
    </div>
  </div>
));
DatePickerInput.displayName = "DatePickerInput";

export default DateRangePicker;
