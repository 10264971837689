import { getFundStateTimeseries } from "@/data/api/fund";

import { useConfigContext } from "@/context/configContext";
import { useQuery } from "@tanstack/react-query";

export interface StateTimeseriesParams {
  startDate: string;
  endDate: string;
}

export const useStateTimeseries = ({
  startDate,
  endDate,
}: StateTimeseriesParams) => {
  const { fund } = useConfigContext();
  return useQuery({
    queryKey: ["timeseries", startDate, endDate],
    queryFn: () => getFundStateTimeseries(fund.name, startDate, endDate),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
};
