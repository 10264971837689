import { DateRangeType } from "@/constants/date";
import {
  subDays,
  subMonths,
  subYears,
  format,
  subWeeks,
  endOfDay,
  getUnixTime,
  startOfDay,
} from "date-fns";

export function formatTimestamp(timestamp: bigint): string {
  const date = new Date(Number(timestamp) * 1000);
  return formatDate(date);
}

export function formatDateUTC(date: Date): string {
  const utcDate = new Date(date.toISOString());
  return formatDate(utcDate);
}

export function formatDate(date: Date): string {
  return format(date, "dd/MM/yyyy HH:mm:ss");
}

export const formatToISODate = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const formatShortDate = (date: Date | null): string => {
  if (!date) return "";
  return format(date, "dd MMM yy");
};

export function getDateRangeForTab(dateRange: DateRangeType) {
  const endDate = new Date();

  let startDate: Date;

  switch (dateRange) {
    case DateRangeType.Day:
      startDate = subDays(endDate, 1);
      break;
    case DateRangeType.Week:
      startDate = subWeeks(endDate, 1);
      break;
    case DateRangeType.Month:
      startDate = subMonths(endDate, 1);
      break;
    case DateRangeType.Year:
      startDate = subYears(endDate, 1);
      break;
    case DateRangeType.All:
      startDate = new Date(0);
      break;
    default:
      startDate = endDate;
  }

  return {
    startDate: formatToISODate(startDate),
    endDate: formatToISODate(endDate),
  };
}

export function getDateRangeTimestamps(
  dates: { startDate: Date; endDate: Date } | undefined,
) {
  if (!dates) return undefined;

  return {
    from: getUnixTime(startOfDay(dates.startDate)).toString(),
    to: getUnixTime(endOfDay(dates.endDate)).toString(),
  };
}
