import { SECTION_IDS } from "@/constants/navigation";
import FundPropertiesData from "./FundPropertiesData";
import FundPropertiesHeader from "./FundPropertiesHeader";
import SafeAddresses from "./SafeAddresses";
import React from "react";
import { useRef } from "react";

function FundProperties() {
  const fundPropertiesRef = useRef(null);

  return (
    <div
      id={SECTION_IDS.FUND_PROPERTIES}
      ref={fundPropertiesRef}
      className="py-10 scroll-mt-28"
    >
      <FundPropertiesHeader />
      <SafeAddresses />
      <FundPropertiesData />
    </div>
  );
}

export default FundProperties;
