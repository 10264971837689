import { formatNavToUSDUnits } from "@/utils/formatters";

import { useStateTimeseries } from "./useStateTimeseries";

export interface HistoricalNavParams {
  startDate: string;
  endDate: string;
}

export const useHistoricalNav = ({
  startDate,
  endDate,
}: HistoricalNavParams) => {
  const { data, error, isFetching, isFetchedAfterMount, isError } =
    useStateTimeseries({ startDate, endDate });

  const chartData = data?.map((fund) => {
    const usdc = Number(formatNavToUSDUnits(fund.usd, false));
    return {
      timestamp: Number(fund.maxTimestamp) * 1000,
      value: usdc,
    };
  });

  return {
    data: chartData,
    isError,
    error,
    isFetching,
    isFetchedAfterMount,
  };
};
