import { useQuery } from "@tanstack/react-query";
import type { DateRangeType } from "@/constants/date";
import { useConfigContext } from "@/context/configContext";
import { calculateScaledSharePrice } from "@/utils/math";
import { getFundStateTimeseries } from "@/data/api/fund";
import { formatToUSD } from "@/utils/formatters";
import { useStateTimeseries } from "./useStateTimeseries";

export interface HistoricalSharePriceParams {
  startDate: string;
  endDate: string;
}

export const useHistoricalSharePrice = ({
  startDate,
  endDate,
}: HistoricalSharePriceParams) => {
  const { data, error, isFetching, isFetchedAfterMount, isError } =
    useStateTimeseries({ startDate, endDate });

  const chartData = data?.map((fund) => {
    const scaledSharePrice = calculateScaledSharePrice(fund.supply, fund.usd);

    return {
      timestamp: Number(fund.maxTimestamp) * 1000,
      value: Number(formatToUSD(scaledSharePrice, false)),
    };
  });

  return {
    data: chartData,
    isError,
    error,
    isFetching,
    isFetchedAfterMount,
  };
};
