import { useState, useEffect, useCallback, useMemo } from "react";
import debounce from "lodash/debounce";

interface Section {
  id: string;
  ref: HTMLElement;
}

interface SectionVisibility {
  id: string;
  visibility: number;
}

const SCROLL_THRESHOLD = 100;

const calculateElementVisibility = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  if (rect.height === 0 || rect.width === 0) return 0;
  if (rect.bottom < 0 || rect.top > windowHeight) return 0;

  const visibleHeight =
    Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
  return Math.max(0, Math.min(100, (visibleHeight / rect.height) * 100));
};

const getSections = (sectionIds: string[]) => {
  return sectionIds
    .map((id) => ({
      id,
      ref: document.getElementById(id),
    }))
    .filter((section): section is Section => section.ref !== null);
};

const calculateSectionVisibilities = (
  sections: Section[],
): SectionVisibility[] => {
  return sections.map((section) => ({
    id: section.id,
    visibility: calculateElementVisibility(section.ref),
  }));
};

const determineActiveSection = (
  sections: Section[],
  visibilities: SectionVisibility[],
): string | null => {
  if (!sections.length) return null;

  if (window.scrollY === 0) {
    return sections[0].id;
  }

  const lastSectionVisibility = visibilities[visibilities.length - 1];
  const isNearBottom =
    window.innerHeight + window.scrollY >=
    document.documentElement.scrollHeight - SCROLL_THRESHOLD;

  if (lastSectionVisibility.visibility > 0 && isNearBottom) {
    return sections[sections.length - 1].id;
  }

  return visibilities.reduce((prev, current) =>
    current.visibility > prev.visibility ? current : prev,
  ).id;
};

export const useVisibleSection = (sectionIds: string[]): string | null => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const handleScroll = useCallback((sections: Section[]) => {
    if (document.hidden) return;
    const visibilities = calculateSectionVisibilities(sections);
    const newActiveSection = determineActiveSection(sections, visibilities);
    setActiveSection(newActiveSection);
  }, []);

  const debouncedHandleScroll = useMemo(
    () => debounce(handleScroll, 50),
    [handleScroll],
  );

  useEffect(() => {
    if (!sectionIds.length) {
      setActiveSection(null);
      return;
    }
    const sections = getSections(sectionIds);

    const visibilities = calculateSectionVisibilities(sections);
    const initialSection = determineActiveSection(sections, visibilities);
    setActiveSection(initialSection);

    const handleScroll = () => {
      debouncedHandleScroll(sections);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
      debouncedHandleScroll.cancel();
    };
  }, [sectionIds, debouncedHandleScroll]);

  return activeSection;
};
