import { URL_PATHS } from "@/constants/navigation";
import { useAuthorizationContext } from "@/context/authorizationContext";
import { Tab } from "./Tab";

interface TransactionsTabProps {
  pathname: string;
  isLoggedIn: boolean;
  isAuthorizationReady: boolean;
}

function TransactionsTab({
  pathname,
  isLoggedIn,
  isAuthorizationReady,
}: TransactionsTabProps) {
  const { showAccessDeniedModal } = useAuthorizationContext();

  const handleTransactionsClick = (e: React.MouseEvent) => {
    if (!isLoggedIn) {
      e.preventDefault();
      showAccessDeniedModal();
    }
  };

  return (
    <Tab
      name="Transactions"
      currentPath={pathname}
      tabPath={URL_PATHS.TRANSACTIONS}
      onClick={handleTransactionsClick}
      disabled={!isAuthorizationReady}
    />
  );
}

export default TransactionsTab;
