"use client";

import { Button } from "@headlessui/react";
import classNames from "classnames";

interface TabProps {
  tabs: string[];
  selectedIndex: number | undefined;
  setCurrentTab: (tab: number) => void;
}

export default function Tabs({ tabs, selectedIndex, setCurrentTab }: TabProps) {
  return (
    <div className="rounded-md border border-gray-900 bg-gray-200 w-fit">
      {tabs.map((tab, index) => (
        <Button
          key={tab}
          onClick={() => setCurrentTab(index)}
          className={classNames(
            "px-3 h-[34px] font-sans text-base leading-5 focus:outline-none text-gray-900",
            {
              "bg-gray-100  font-bold": selectedIndex === index,
              "hover:bg-gray-400":
                selectedIndex !== index || selectedIndex === undefined,
              "rounded-l-md": index === 0,
              "rounded-r-md": index === tabs.length - 1,
            },
          )}
        >
          {tab}
        </Button>
      ))}
    </div>
  );
}
