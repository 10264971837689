import { useCallback, useEffect } from "react";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import classNames from "classnames";
import InteractiveDot from "../Icons/InteractiveCircle";
import { URL_PATHS } from "@/constants/navigation";

interface SubtabProps {
  children: React.ReactNode;
  sectionId: string;
  isActive: boolean;
}

export function Subtab({ sectionId, children, isActive }: SubtabProps) {
  const searchParams = useSearchParams();
  const fullPath = `${URL_PATHS.REPORT}#${sectionId}`;

  const scrollToSection = useCallback(() => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [sectionId]);

  useEffect(() => {
    if (searchParams && window.location.hash === `#${sectionId}`) {
      scrollToSection();
    }
  }, [sectionId, searchParams, scrollToSection]);

  return (
    <Link href={fullPath} scroll={false} onClick={scrollToSection}>
      <div className="py-2 flex flex-row items-center gap-1 group">
        <InteractiveDot active={isActive} />
        <span
          className={classNames(
            "font-mono font-bold text-xl pl-2",
            isActive
              ? "text-gray-900"
              : "text-gray-600 group-hover:text-gray-900",
          )}
        >
          {children}
        </span>
      </div>
    </Link>
  );
}
