import { useFundPortfolioContext } from "@/context/fundPortfolioContext";
import { useMemo } from "react";
import {
  AssetDistributionWrapper,
  AssetsDistributionLoader,
} from "../PortfolioAssetsDistribution";
import { DoughnutChart } from "./PortfolioDoughnutChart";
import { groupBy, map, orderBy, sumBy } from "lodash";
import ErrorDisplay from "@/components/ErrorDisplay";
import { getDebankProtocolName } from "@/constants/wallet";

export function FundsByProtocolChart() {
  const { data, isLoading, isError } = useFundPortfolioContext();

  const chartData = useMemo(() => {
    const groupedProtocols = groupBy(data?.protocols, "name");

    const aggregatedData = map(groupedProtocols, (group, protocolName) => {
      const totalNetUsdValue = sumBy(group, (protocol) =>
        sumBy(
          protocol.portfolio_item_list,
          (item) => item.stats?.net_usd_value || 0,
        ),
      );

      const protocol = getDebankProtocolName(protocolName);

      return {
        label: protocol,
        value: totalNetUsdValue,
      };
    });

    const sortedData = orderBy(aggregatedData, ["value"], ["desc"]);

    const topProtocols = sortedData.slice(0, 5);

    const othersValue = sumBy(sortedData.slice(5), "value");

    const finalData =
      othersValue > 0
        ? [...topProtocols, { label: "Others", value: othersValue }]
        : topProtocols;

    return finalData;
  }, [data]);

  if (chartData?.length === 0) return null;

  return (
    <AssetDistributionWrapper title="Total funds by protocol">
      {isLoading ? (
        <AssetsDistributionLoader />
      ) : isError ? (
        <div className="flex-col flex py-20 justify-center">
          <ErrorDisplay />
        </div>
      ) : (
        <DoughnutChart data={chartData} />
      )}
    </AssetDistributionWrapper>
  );
}
