import React, { useMemo, useRef, useState, useEffect } from "react";
import { Tabs } from "@/components";
import LineChart from "./LineChart";
import { dateRanges, DateRangeType } from "@/constants/date";
import { formatToISODate } from "@/utils/date_time";
import LoadingErrorChart from "./LoadingErrorChart";
import DateRangePicker, {
  type DateRangePickerRef,
} from "@/components/DatePicker/DateRangePicker";
import { useFundStateContext } from "@/context/fundStateContext";

type BaseVariationChartProps = {
  title: string;
  data: any;
  isError: boolean;
  isLoading: boolean;
  label: string;
  currentTab: DateRangeType;
  customDateRange?: { startDate: string; endDate: string };
  onTabChange: (tab: DateRangeType) => void;
  onCustomDateChange: (dates?: { startDate: string; endDate: string }) => void;
};

function BaseVariationChart({
  title,
  data,
  isError,
  isLoading,
  label,
  currentTab,
  customDateRange,
  onTabChange,
  onCustomDateChange,
}: BaseVariationChartProps) {
  const dateRangePickerRef = useRef<DateRangePickerRef>(null);
  const { data: fundData } = useFundStateContext();

  return (
    <div className="pt-5 space-y-10 pr-10">
      <div className="flex justify-between items-center">
        <span className="font-mono text-gray-900 font-bold text-2xl">
          {title}
        </span>
        <div className="flex items-center gap-5">
          <Tabs
            tabs={dateRanges}
            selectedIndex={
              customDateRange ? undefined : dateRanges.indexOf(currentTab)
            }
            setCurrentTab={(tab) => {
              onTabChange(dateRanges[tab]);
              onCustomDateChange(undefined);
              dateRangePickerRef.current?.reset();
            }}
          />
          <DateRangePicker
            ref={dateRangePickerRef}
            minDate={
              fundData?.startTimestamp
                ? new Date(Number(fundData.startTimestamp) * 1000)
                : undefined
            }
            maxDate={new Date()}
            onDateChange={(dates) => {
              onCustomDateChange(
                dates
                  ? {
                      startDate: formatToISODate(dates?.startDate),
                      endDate: formatToISODate(dates?.endDate),
                    }
                  : undefined,
              );
              onTabChange(DateRangeType.All);
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <LoadingErrorChart isError={isError} />
      ) : (
        <div className="h-96 w-full">
          <LineChart data={data} dateRange={currentTab} label={label} />
        </div>
      )}
    </div>
  );
}

export default BaseVariationChart;
