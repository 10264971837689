import { useConfigContext } from "@/context/configContext";
import { getFundPerformance } from "@/data/api/fund";
import { useQuery } from "@tanstack/react-query";

export const useFundPerformance = () => {
  const { fund } = useConfigContext();
  return useQuery({
    queryKey: ["fundPerformance", fund],
    queryFn: () => getFundPerformance(fund.name),
    retry: false,
    refetchOnWindowFocus: false,
  });
};
