import { z } from "zod";
import { paginationSchema } from "./pagination";
import { addressSchema } from "./config";
import type { Address } from "viem";

enum OperationType {
  Deposit = "deposit",
  Redemption = "redemption",
  ManagementFee = "management",
  PerformanceFee = "performance",
  Fees = "fees",
}

export enum OperationRequestType {
  All = "all",
  Deposit = "deposit",
  Redemption = "redemption",
  Fee = "fee",
}

enum OperationState {
  Pending = "pending",
  Confirmed = "confirmed",
  Denied = "denied",
  Canceled = "canceled",
}

export enum OperationRequestState {
  All = "all",
  Pending = "pending",
  Confirmed = "confirmed",
  Denied = "denied",
  Canceled = "canceled",
}

export const feesSchema = z.object({
  address: z.string().optional(),
  mgmtFee: z.coerce.bigint(),
  perfFee: z.coerce.bigint(),
  currentAmount: z.coerce.bigint().optional(),
  start: z.coerce.date().optional(),
  end: z.coerce.date().optional(),
});

export const operationPendingFeesSchema = z.object({
  nonce: z.coerce.bigint(),
  fees: z.array(feesSchema),
});

export type TableOperationCommon = {
  date: string;
  amount: bigint;
  type: OperationType;
  state: OperationState;
  beneficiary?: Address;
};

const operationSchema = z.object({
  type: z.nativeEnum(OperationType),
  requestId: z.coerce.bigint(),
  nav: z.coerce.bigint(),
  assets: z.coerce.bigint(),
  shares: z.coerce.bigint(),
  supply: z.coerce.bigint(),
  state: z.nativeEnum(OperationState),
  ttl: z.coerce.bigint(),
  priceImpactMargin: z.coerce.bigint(),
  nonce: z.number(),
  fee: z.coerce.bigint().nullable(),
  beneficiary: addressSchema,
  insertedTransactionHash: z.string(),
  updatedTransactionHash: z.string(),
  insertedBlockNumber: z.coerce.bigint(),
  insertedBlockTimestamp: z.coerce.bigint(),
  updatedBlockNumber: z.coerce.bigint(),
  updatedBlockTimestamp: z.coerce.bigint(),
});

export const operationsSchema = z.array(operationSchema);

export const operationResponseSchema = z.object({
  data: operationsSchema,
  pagination: paginationSchema.optional(),
});

type Operations = z.infer<typeof operationsSchema>;
type Operation = z.infer<typeof operationSchema>;
export type OperationPendingFees = z.infer<typeof operationPendingFeesSchema>;
export type TableOperation = TableOperationCommon & {
  operation?: Operation;
  operatorPendingFees?: OperationPendingFees;
};

export type PendingFees = z.infer<typeof feesSchema>;

export { OperationType, OperationState };
export type { Operations, Operation };
