import axios from "../axios";
import { parseWithSchema } from "../parser";
import {
  feesSchema,
  operationPendingFeesSchema,
  OperationRequestState,
  type OperationRequestType,
  operationResponseSchema,
} from "../interfaces/operations";

interface OperationsParams {
  fundName: string;
  operationType: OperationRequestType;
  operationState?: OperationRequestState;
  limit?: number;
  page?: number;
  from?: string;
  to?: string;
}

export async function getInvestorOperations({
  fundName,
  operationType,
  operationState = OperationRequestState.All,
  limit,
  page,
  from,
  to,
}: OperationsParams) {
  try {
    const res = await axios.get(`/fund/${fundName}/investor/operations`, {
      params: {
        type: operationType,
        state: operationState,
        page,
        limit,
        from,
        to,
      },
    });
    return operationResponseSchema.parse(res.data);
  } catch (error) {
    console.error("Error getting operations:", error);
    throw error;
  }
}

export async function getOperations({
  fundName,
  operationType,
  operationState = OperationRequestState.All,
  limit,
  page,
  from,
  to,
}: OperationsParams) {
  try {
    const res = await axios.get(`/fund/${fundName}/operations`, {
      params: {
        type: operationType,
        state: operationState,
        page,
        limit,
        from: from,
        to: to,
      },
    });
    return operationResponseSchema.parse(res.data);
  } catch (error) {
    console.error("Error getting operations:", error);
    throw error;
  }
}

export const getInvestorFees = async ({
  fundName,
  address,
}: {
  fundName: string;
  address: string;
}) => {
  try {
    const res = await axios.get(`/fund/${fundName}/fees/${address}`);
    return parseWithSchema(res.data, feesSchema);
  } catch (error) {
    console.error("Error getting investor fees:", error);
    throw error;
  }
};

export async function getOperatorPendingFees({
  fundName,
  date,
}: {
  fundName: string;
  date: number;
}) {
  try {
    const res = await axios.get(`/fund/${fundName}/fees`, {
      params: { date },
    });

    return parseWithSchema(res.data, operationPendingFeesSchema);
  } catch (error) {
    console.error("Error getting fees:", error);
    throw error;
  }
}
export async function getPendingFees({ fundName }: { fundName: string }) {
  try {
    const res = await axios.get(`/fund/${fundName}/investor/fees`);
    return parseWithSchema(res.data, feesSchema);
  } catch (error) {
    console.error("Error getting pending fees:", error);
    throw error;
  }
}
