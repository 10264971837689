import SectionTitle from "../SectionTitle";
import React from "react";
import FundAssetsDistribution from "./PortfolioAssetsDistribution";
import { FundPortfolioProvider } from "@/context/fundPortfolioContext";
import PortfolioWallet from "./PortfolioWallet";
import PortfolioMoneyMarkets from "./PortfolioMoneyMarkets";
import { useRef } from "react";
import { SECTION_IDS } from "@/constants/navigation";

function Portfolio() {
  const portfolioRef = useRef(null);

  return (
    <FundPortfolioProvider>
      <div
        id={SECTION_IDS.PORTFOLIO}
        ref={portfolioRef}
        className="py-10 border-t border-t-gray-800 scroll-mt-24"
      >
        <div className="flex flex-col space-y-10">
          <div className="px-5">
            <SectionTitle>Portfolio</SectionTitle>
          </div>
          <FundAssetsDistribution />
          <PortfolioWallet />
          <PortfolioMoneyMarkets />
        </div>
      </div>
    </FundPortfolioProvider>
  );
}

export default Portfolio;
