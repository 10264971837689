"use client";

import { usePathname } from "next/navigation";
import { useAuthorizationContext } from "@/context/authorizationContext";
import { URL_PATHS } from "@/constants/navigation";
import ReportTab from "./ReportTab";
import TransactionsTab from "./TransactionsTab";

export default function Sidebar() {
  const pathname = usePathname();
  const { isLoggedIn, isAuthorizationReady } = useAuthorizationContext();

  return (
    <nav className="fixed w-64 h-full border-r border-gray-400 z-30 mt-28 py-5 pl-10 pr-5 bg-gray-100">
      <ReportTab pathname={pathname} isLoggedIn={isLoggedIn} />
      <TransactionsTab
        pathname={pathname}
        isLoggedIn={isLoggedIn}
        isAuthorizationReady={isAuthorizationReady}
      />
    </nav>
  );
}
