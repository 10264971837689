import { URL_PATHS, reportSections } from "@/constants/navigation";
import { useVisibleSection } from "@/hooks/core/useVisibleSection";
import { Tab } from "./Tab";
import { Subtab } from "./Subtab";

interface ReportTabProps {
  pathname: string;
  isLoggedIn: boolean;
}

const SECTION_LABELS: Record<string, string> = {
  "fund-properties": "Fund properties",
  "fund-performance": "Fund performance",
  portfolio: "Portfolio",
  "user-position": "My position",
};

function ReportTab({ pathname, isLoggedIn }: ReportTabProps) {
  const isReportPage = pathname === URL_PATHS.REPORT;
  const sections = reportSections(isLoggedIn);
  const activeSection = useVisibleSection(isReportPage ? sections : []);

  return (
    <div>
      <Tab name="Report" currentPath={pathname} tabPath={URL_PATHS.REPORT} />

      <div className="ml-4 mt-[-14px]">
        {sections.map((sectionId) => (
          <Subtab
            key={sectionId}
            sectionId={sectionId}
            isActive={activeSection === sectionId}
          >
            {SECTION_LABELS[sectionId]}
          </Subtab>
        ))}
      </div>
    </div>
  );
}

export default ReportTab;
