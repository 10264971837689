import { z } from "zod";

const supplySchema = z.object({
  fund: z.string(),
  blockNumber: z.coerce.bigint(),
  blockTimestamp: z.coerce.bigint(),
  totalSupply: z.coerce.bigint(),
});

const navPerChainEntrySchema = z.object({
  usd: z.coerce.bigint(),
});

const navSchema = z.object({
  id: z.number(),
  fund: z.string(),
  minTimestamp: z.coerce.bigint(),
  maxTimestamp: z.coerce.bigint(),
  insertedAt: z.string(),
  navReadingId: z.number(),
  readings: z.number(),
  usd: z.coerce.bigint(),
});

export const latestStateSchema = z.object({
  supply: supplySchema,
  nav: navSchema,
  navPerChain: z.record(navPerChainEntrySchema).optional(),
  startTimestamp: z.coerce.bigint().optional(),
  usdcBalance: z.coerce.bigint().optional(),
});

const fundTimeserieSchema = z.object({
  maxTimestamp: z.coerce.bigint(),
  supply: z.coerce.bigint(),
  usd: z.coerce.bigint(),
});

const tokenSchema = z.array(
  z.object({
    id: z.string(),
    chain: z.string(),
    name: z.string().nullable(),
    symbol: z.string().nullable(),
    display_symbol: z.string().nullable(),
    optimized_symbol: z.string().nullable(),
    decimals: z.number().nullable(),
    logo_url: z.string().url().nullable(),
    protocol_id: z.string().optional(),
    price: z.number(),
    price_24h_change: z.number().nullable().optional(),
    is_verified: z.boolean().optional(),
    is_core: z.boolean(),
    is_wallet: z.boolean().optional(),
    time_at: z.number().nullable(),
    amount: z.number(),
    raw_amount: z.number().optional(),
    raw_amount_hex_str: z.string().optional(),
  }),
);

const statsSchema = z.object({
  asset_usd_value: z.number(),
  debt_usd_value: z.number(),
  net_usd_value: z.number(),
});

const detailSchema = z.object({
  supply_token_list: tokenSchema.optional(),
  reward_token_list: tokenSchema.optional(),
  borrow_token_list: tokenSchema.optional(),
});

const projectSchema = z.object({
  id: z.string(),
  name: z.string(),
  site_url: z.string().url(),
  logo_url: z.string().url(),
});

const proxySchema = z.object({
  project: projectSchema.optional(),
  proxy_contract_id: z.string().optional(),
});

const portfolioItemSchema = z.object({
  stats: statsSchema,
  update_at: z.number(),
  name: z.string(),
  details_type: z.array(z.string()).optional(),
  detail: detailSchema,
  proxy_detail: proxySchema,
  position_index: z.string().optional(),
});

const complexProtocolSchema = z.array(
  z.object({
    id: z.string(),
    chain: z.string(),
    name: z.string().nullable(),
    logo_url: z.string().url().nullable(),
    site_url: z.string().url().nullable(),
    has_supported_portfolio: z.boolean(),
    portfolio_item_list: z.array(portfolioItemSchema),
  }),
);

export const fundPerformanceSchema = z.object({
  beta: z.number().optional(),
  sharpeRatio: z.number().optional(),
  alpha: z.number().optional(),
});

export const fundPortfolioSchema = z.object({
  protocols: complexProtocolSchema,
  tokens: tokenSchema,
});

type NavPerChain = z.infer<typeof latestStateSchema>["navPerChain"];
type Protocol = z.infer<typeof complexProtocolSchema>[0];
type TokenList = z.infer<typeof tokenSchema>;
type Token = z.infer<typeof tokenSchema>[0];

export const fundTimeseriesSchema = z.array(fundTimeserieSchema);

export type { NavPerChain, Token, TokenList, Protocol };
