import Link from "next/link";
import classNames from "classnames";
import InteractiveDot from "../Icons/InteractiveCircle";

interface TabProps {
  currentPath: string;
  tabPath: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  name?: string;
}

export function Tab({
  currentPath,
  tabPath,
  name,
  onClick,
  disabled = false,
}: TabProps) {
  const active = currentPath === tabPath;

  return (
    <Link
      href={tabPath}
      onClick={(e) => (disabled ? e.preventDefault() : onClick?.(e))}
    >
      <div className="py-5 flex flex-row items-center gap-2 group">
        <InteractiveDot active={active} />
        <span
          className={classNames(
            "font-mono font-bold text-xl",
            active
              ? "text-gray-900"
              : "text-gray-600 group-hover:text-gray-900",
          )}
        >
          {name}
        </span>
      </div>
    </Link>
  );
}
